import React from "react"
import Faq from "../components/faq"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const FaqPage = ({ data }) => {
  const { t } = useTranslation(["index", "faq"])
  return (
    <Layout>
      <SEO
        title={t("faq:metadata.title")}
        description={t("faq:metadata.desc")}
        image="faq.jpg"
      />
      <div className="container-fluid sp-pb-9 page-intro color--black">
        <div className="row">
          <div className="content text--sm  offset-lg-2 col-lg-8 col-xl-6">
            <h1 className="title text--sm">
              {t("index:Frequently Asked Questions")}
            </h1>
          </div>
        </div>
      </div>
      <Faq
        className="bg--white sp-pb-9 color--black faq-page"
        faq={data.faq.edges}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query FaqQuery($language: String) {
    faq: allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $language } }
        fileAbsolutePath: { regex: "/home/faq/" }
      }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            lang
          }
          html
        }
      }
    }
  }
`

export default FaqPage
