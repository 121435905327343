import React from "react"
import { gsap } from "gsap"

const Faq = props => {
  let refs = []

  const toggle = (e, node) => {
    e.preventDefault()

    let item = refs[node]

    item.active = item.active ? 0 : 1

    item.classList.toggle("active")

    gsap.fromTo(
      refs[node].getElementsByClassName("faq__item__a")[0],
      0.2,
      {
        height: item.active ? 0 : "auto",
      },
      {
        height: item.active ? "auto" : 0,
      }
    )
  }

  return (
    <div className={`${props.className} faq`}>
      <div className="faq__accordion">
        {props.faq.map((o, i) => (
          <div
            className="faq__item"
            key={i}
            ref={ref => {
              refs[i] = ref
            }}
          >
            <div
              className="faq__item__q sp-pt-2 sp-pb-4 text--md"
              role="button"
              style={{
                cursor: "pointer",
              }}
              onClick={e => {
                toggle(e, i)
              }}
            >
              {o.node.frontmatter.title}
            </div>
            <div className="faq__item__a text--md color--gray2">
              <div
                className="sp-pb-7 faq__item__a__inner"
                dangerouslySetInnerHTML={{ __html: o.node.html }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq
